import LAYOUT_CONST from 'constant';

// types
import { ConfigProps } from 'types/config';

export const BASE_PATH = '';
export const DASHBOARD_PATH = '/sample-page';

export const HORIZONTAL_MAX_ITEM = 6;

const config: ConfigProps = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT,
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, 
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'dark', 
    presetColor: 'theme5', 
    locale: 'en', 
    rtlLayout: false,
    container: false
};

export default config;
